.HeaderSet {
    --border-width: 5px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 0;
    margin: 0;
    list-style: none;
}

.Header {
    flex: 1;

    position: relative;
    padding: 0.75em;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    font-size: 1em;
    text-decoration: none;
    color: var(--text-color);
    transition: padding 0.25s ease;
}

@media screen and (max-width: 480px) {
    .Header {
        padding-left: 0.4em;
        padding-right: 0.4em;
    }
}

.HeaderisActive {
    background-color: var(--color-primary);
    color: white;
}

.HeaderDisabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;

    &:hover {
        background-color: inherit;
    }
}

.HeaderArrow {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 46%;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
    }

    &.HeaderisActive:after {
        background-color: inherit;
    }
}

.Panel {
    margin: 1em;
}

/* Stacked */
.HeaderSetStacked {
    flex-direction: column;
    position: relative;

    & button {
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 1;
    }

    & .Header {
        display: none;
    }

    & .HeaderisActive {
        display: block;
        order: -1;
        padding-top: 17px;
    }
}
.HeaderSetExpanded {
    & .Header {
        display: block;
    }
}

/* THEMES */
.HeaderSetCompact {
    & .Header {
        background-color: var(--color-step-100);
        z-index: 1;
    }

    & .HeaderisActive {
        background-color: var(--color-primary);
        color: var(--text-color-inverse);
    }
}

.HeaderSetLoose {
    background-color: var(--gray-lighter);

    & .Header {
        display: inline-block;
        position: relative;
        padding: 1.5em;
        font-weight: 700;
        color: white;

        &:after {
            /*
                Do not put border-right, it will break in IE when JS code when
                try measure widths
             */
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: white;
            height: 100%;
            width: 1px;
        }
    }
}
