.Button {
    background: transparent;
    border: none;
    padding: 0;
    margin-left: auto;
    margin-right: 12px;
    color: var(--color-step-950);
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background-color: var(--color-step-400);
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
}
