.TagActive {
    white-space: break-spaces;
    text-align: center;
    background-color: var(--danger);
    color: white;

    & > a:hover {
        color: white;
    }

    & > a:after {
        display: none;
    }
}

.TagExpired {
    white-space: break-spaces;
    text-align: center;
    background-color: var(--warning);
    color: white;

    & > a:hover {
        color: white;
    }

    & > a:after {
        display: none;
    }
}

.Headline h2 span {
    margin-left: 0.5em;
}

.Content {
    margin: 0 1em;
}

.Subheadline {
    margin: 1em;
    font-weight: 700;
    font-size: 1.25em;
}
