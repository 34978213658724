.List {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    gap: 0.75rem;
}

.Inline {
    & .Child {
        flex: 0 1 100%;
    }
}

.Condensed {
    & .Child {
        padding: 0.25em 0;
    }
}

.Bordered {
    & .Child:not(.Block),
    & .Definition.Block {
        border-bottom: 1px solid var(--gray-lighter);
    }
}

.Child {
    flex: 0 1 calc(50% - 0.375rem);

    margin: 0;
    padding: 0 0 0.75rem;
    color: var(--gray);

    &.Block {
        flex: 100%;
    }
}

.Term {
    composes: Child;

    color: var(--gray-darker);
    font-weight: 700;
}

.Definition {
    composes: Child;

    & > ul {
        margin: 0;
    }
}
