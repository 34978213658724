.Section {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.Section--Title {
    color: var(--color-primary);
    text-transform: uppercase;
    padding-bottom: 0.75em;
    margin: 1em 0;
    border-bottom: 1px solid var(--gray-lighter);
}

.Activities {
    display: flex;
    align-items: center;
    font-size: 0.75em;

    & dt {
        display: none;
    }

    & dd {
        margin: 0;
    }
}
