.selectedItem {
    text-align: center;
}

.controlsContainer {
    --highlight-width: auto;
    --highlight-x-pos: 0;

    display: flex;
    margin: 0px 0 25px;
}

.controls {
    display: inline-flex;
    justify-content: space-between;
    background: var(--background-color);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #eee;
    border-radius: 10px;
    /* max-width: 500px; */
    padding: 5px 5px;
    margin: auto;
    overflow: hidden;
    position: relative;

    & input {
        opacity: 0;
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        cursor: pointer;
        height: 100%;
    }

    &:before {
        content: '';
        background: var(--color-primary);
        border-radius: 8px;
        width: var(--highlight-width);
        transform: translateX(var(--highlight-x-pos));
        transition: transform 0.3s ease, width 0.3s ease;
        position: absolute;
        top: 8px;
        bottom: 8px;
        left: 0;
        z-index: 0;
    }
}

.segment {
    width: fit-content;
    position: relative;
    text-align: center;
    z-index: 1;

    & label {
        cursor: pointer;
        display: block;
        font-weight: 600;
        padding: 6px;
        transition: color 0.5s ease;
        font-size: 14px;
        /* TODO:: convert styles to rem */
    }
}

.active label {
    color: var(--text-color-inverse);
}
