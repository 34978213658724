.Container {
    --border: 1px solid var(--color-step-200);

    border-top: var(--border);

    &:last-child {
        border-bottom: var(--border);
    }

    & > summary {
        position: relative;
        cursor: pointer;
        list-style: none;
        background-color: var(--background-color);
        color: var(--text-color);
        font-weight: 700;
        padding: 1em 3em 1em 1em;

        &::-webkit-details-marker {
            display: none;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 3em;
            background-image: url('../icons/add.svg');
            color: var(--text-color);
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &[open] > summary::after {
        background-image: url('../icons/remove.svg');
    }
}
