.low {
    color: black;
    background-color: #50b848;
}
.moderate {
    color: black;
    background-color: #fff200;
}
.considerable {
    color: black;
    background-color: #f7941e;
}
.high {
    color: black;
    background-color: #ed1c24;
}
.extreme {
    color: white;
    background-color: #231f20;
}
.spring,
.earlyseason {
    color: white;
    background-color: #0072c6;
}
.norating,
.noforecast {
    color: black;
    background-color: white;
}
.low--colourblind {
    color: black;
    background-color: #fcffa4;
}
.moderate--colourblind {
    color: black;
    background-color: #f98e09;
}
.considerable--colourblind {
    color: white;
    background-color: #bc3754;
}
.high--colourblind {
    color: white;
    background-color: #57106e;
}
.extreme--colourblind {
    color: white;
    background-color: #000004;
}
.spring--colourblind,
.earlyseason--colourblind {
    color: white;
    background-color: #0072c6;
}
.norating--colourblind,
.noforecast--colourblind {
    color: black;
    background-color: white;
}
