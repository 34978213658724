.Container {
    position: relative;

    & table {
        position: inherit;

        & > thead {
            border-bottom: none;
        }
    }
}

.Content {
    overflow-x: scroll;
}
