.top {
    padding-top: 1em;
}

.right {
    padding-right: 1em;
}

.bottom {
    padding-bottom: 1em;
}

.left {
    padding-left: 1em;
}

.vertical {
    composes: top;
    composes: bottom;
}

.horizontal {
    composes: right;
    composes: left;
}

.all {
    composes: vertical;
    composes: horizontal;
}
