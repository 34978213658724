.Set {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.Item {
    display: inline-flex;
    align-items: center;
    margin: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 0.75em;
    text-transform: uppercase;
    background-color: var(--gray-lighter);
    letter-spacing: 0.1em;

    &:first-child {
        margin-left: 0;
    }
}
