.Day {
    border-bottom: 2px solid transparent;

    &:first-child {
        border-bottom-width: 4px;

        & .Title {
            font-size: 1.25em;
        }
    }
}

.Title {
    composes: import SectionHeader from '../Forecast.module.css';
    text-transform: capitalize;
    padding-left: 0;
    padding-right: 0;

    @media print {
        & time:after {
            content: ', ' attr(datetime);
        }
    }
}

.Row {
    display: flex;
    color: black;

    &:not(:last-of-type) {
        border-bottom: 2px solid transparent;
    }
}

.Elevation {
    composes: import SubSectionHeader from '../Forecast.module.css';

    flex: 40%;
}

.Rating {
    composes: import SubSectionHeader from '../Forecast.module.css';

    flex: 60%;
    white-space: nowrap;
}
