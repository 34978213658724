.Wrapper {
    margin-left: 1em;
    margin-bottom: 0.5em;
    background: white;
    padding: 0.25em;
    border-radius: 2px;
}

.Logo {
    display: block;
    margin: 0;
    max-height: 50px;
    max-width: 150px;
}
