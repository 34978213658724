.Container {
    display: grid;
    margin: 1em 0;
    border-width: 2px;
    border-style: solid;
    border-color: var(--color-step-200);
}

.Header {
    margin: 0;
    padding: 0.75em;
    font-size: 1.1em;
    font-weight: normal;
    background-color: var(--background-color);
    color: var(--text-color);
    border: 2px solid var(--color-step-200);
    margin: -2px -2px 0 -2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.FigureSet {
    --figure-span: 2;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
}

.Figure {
    grid-column-start: auto;
    grid-column-end: span var(--figure-span);
    background-color: #e6e6e6; /* This should NOT change with light/dark mode as images are displayed above it */
    display: flex;
    flex-direction: column;
    min-height: 190px;
    display: flex;
    flex-direction: column;
    margin: 0;

    & p {
        margin: 1em;
        color: black; /* This also should NOT change with light/dark mode so that the alt text has enough contrast if images aren't displayed */
    }

    & figcaption {
        margin: 0;
        padding: 0.75em;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: var(--gray-darker);
        background-color: var(--gray-lighter);
    }

    & > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.Half > .Figure {
    grid-column-end: span 2;

    &:nth-child(odd) {
        border-left: 2px solid var(--gray-lighter);
    }
}

.Quarter > .Figure {
    grid-column-end: span 1;

    &:not(:first-of-type) {
        border-left: 2px solid var(--gray-lighter);
    }
}

.SubSection {
    /* color: var(--text-color); */
    background-color: var(--background-color);
}

.Comment {
    composes: SubSection;

    & > * {
        margin: 0;

        &:not(:empty) {
            padding: 1em;
        }
    }
}

.Advice {
    composes: SubSection;

    & > div {
        padding: 1em;

        & > * {
            margin: 0;
        }
        & > ul {
            padding-left: 1.5em;
        }
    }
}
