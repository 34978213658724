.Container {
    position: relative;
    width: 95%;
    margin: auto;
}

.Control {
    border-radius: 2em;
    position: relative;
}

.Input {
    border-radius: 5px;
    padding: 0.125em 2em;
    background-color: var(--background-color);
    color: var(--text-color);
    width: 100%;
}

.Clear {
    background: transparent;
    border: none;
    color: var(--text-color);
    padding-right: 0;
}

.BoxWithShadow {
    font-size: 16px;
    background: var(--component-primary-bg-inactive);
    color: white;
    border-radius: 4px;
    margin-top: 8px;
    overflow: hidden;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.PaddedText {
    padding: 0.5em 0.75em;
}

.Option {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.5em 0.75em;

    &:hover {
        background-color: var(--component-primary-bg-hover);
    }
}

.Option--Selected {
    composes: Option;

    &,
    &:hover {
        background-color: var(--component-primary-bg-active);
    }
}
