.Figure {
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 250px;

    & img {
        margin: 0;
        opacity: 1;
        transition: opacity 1s;
        height: auto;
        width: 100%;
        display: inherit;
    }
}

.Pending {
    & img {
        opacity: 0;
    }

    background: var(--gray-lighter);
}

.Resolved {
}

.Rejected {
    & img {
        background-color: var(--error);
        color: white;
    }
}
