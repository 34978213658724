.Button {
    position: absolute;
    top: 116px;
    right: 10px;
    width: 29px;
    height: 29px;
    border-radius: 4px;
    padding: 0;
    border: none;
    background-color: var(--background-color);
    color: var(--text-color);
    box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
}
.Icon {
    padding: 3px;
}
.Inactive {
    opacity: 0.38;
}
